const EmailData = {
  "North America": {
    Quote: "ASCMOM_EmersonLLLPQuotes@devocmxchange.emerson.com",
    SupportRequest: "ASCMOM_SupportSupportRequest@devocmxchange.emerson.com",
  },
  "Asia Pacific": {
    INDIA: {
      Quote: "ASCMOM_AsiaIndiaMumbaiQuotes@devocmxchange.emerson.com",
      SupportRequest:
        "ASCMOM_AsiaIndiaMumbaiSalesOrders@devocmxchange.emerson.com",
    },
    SINGAPORE: {
      Quote: "ASCMOM_AsiaSingaporeQuotes@devocmxchange.emerson.com",
      SupportRequest:
        "ASCMOM_AsiaSingaporeSalesOrders@devocmxchange.emerson.com",
    },
  },
  "Middle East and Africa": {
    DUBAI: {
      Quote: "ASCMOM_MEAuaedubaiquote@devocmxchange.emerson.com",
      SupportRequest: "ASCOM_MEAUAEDubaiSalesOrders@devocmxchange.emerson.com",
    },
    DAR_AL_IBTIKAR: {
      Quote: "ASCMOM_MEAIraqBasraQuote@devocmxchange.emerson.com",
      SupportRequest:
        "ASCMOM_MEAIraqBasraSalesOrders@devocmxchange.emerson.com",
    },
    KUWAIT: {
      Quote: "ASCMOM_MEAKuwaitKuwaitCityQuote@devocmxchange.emerson.com",
      SupportRequest:
        "ASCMOM_MEAKuwaitKuwaitCitySalesOrders@devocmxchange.emerson.com",
    },
    MOROCCO: {
      Quote: "ASCMOM_MEANAMoroccoQuote@devocmxchange.emerson.com",
      SupportRequest:
        "ASCMOM_MEANAMoroccoSalesOrders@devocmxchange.emerson.com",
    },
    QATAR: {
      Quote: "ASCMOM_MEAQatarDohaQuote@devocmxchange.emerson.com",
      SupportRequest:
        "ASCMOM_MEAQatarDohaSalesOrders@devocmxchange.emerson.com",
    },
    "SAUDI ARABIA": {
      Quote: "ASCMOM_MEASAAlKhobarQuote@devocmxchange.emerson.com",
      SupportRequest:
        "ASCMOM_MEASAAlKhobarSalesOrders@devocmxchange.emerson.com",
    },
    "ABU DHABI": {
      Quote: "ASCMOM_MEAUAEAbuDhabiQuote@devocmxchange.emerson.com",
      SupportRequest:
        "ASCMOM_MEAUAEAbuDhabiSalesOrders@devocmxchange.emerson.com",
    },
    NIGERIA: {
      Quote: "ASCMOM_MEAWANigeriaQuote@devocmxchange.emerson.com",
      SupportRequest:
        "ASCMOM_MEAWANigeriaSalesOrders@devocmxchange.emerson.com",
    },
  },
};
export default EmailData;
